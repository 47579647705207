import React, {} from "react"
import Layout from '../components/layout.js';
import { 
   Box, 
   Text,
   Image,
   Button,
   Center,
   Heading,
   Input,
   InputLeftElement,
   InputGroup,
   Spinner,
   Link,
   NumberInput,
   NumberInputField,
   NumberInputStepper,
   NumberIncrementStepper,
   NumberDecrementStepper,
   Accordion,
   AccordionItem,
   AccordionButton,
   AccordionPanel,
   AccordionIcon,
   Select,
   useToast,
   AspectRatio,
} from "@chakra-ui/react"

const Disclaimers = (pageprops) => {
   
   return (
      <Layout uri={pageprops.uri}>
         <Box p="1rem" fontSize="1rem">
            <Center><Heading>-利用規約-</Heading></Center>
            <Box p="1rem">
               <Text m="0.5rem">本規約はバズクラッチ(以下「運用者」といいます。)及び関連する Webサイトを通じて提供するサービス（以下総称して「本サービス」といいます。）をご利用される個人及び法人（以下「利用者」といいます。）と運営者との間に適用され、本サービスの利用に関して生ずるすべての関係に適用されるものとします。</Text>
               <Text m="0.5rem">利用者が本サービスをご利用されるにあたっては、以下の利用規約をお読み頂き、同意される場合にのみご利用下さい。なお、本規約につきましては予告なく変更することがありますので、あらかじめ御了承下さい。</Text>
               <Text m="1rem">第１条【サービス】</Text>
               <Text m="0.5rem">１． 当サイトの利用に際してはウェブにアクセスする必要がありますが、利用者は自らの費用と責任に必要な機器・ソフトウェア・通信手段等を用意し適切に接続・操作することとします。</Text>
               <Text m="0.5rem">２． 運用者は、当サイトが提供及び付随するサービスに対する保証行為を一切しておりません。また、運用者は、当サイトの提供するサービスの不確実性・サービス停止等に起因する利用者への損害について、一切責任を負わないものとします。詳細については、「免責事項について」をご覧下さい。</Text>
               <Text m="1rem">第２条【第三者の広告の掲載】</Text>
               <Text m="0.5rem">当サイトでは、Google広告などの第三者の広告コンテンツを掲載しております。これらの広告は、ユーザーの興味や関心に基づいて配信されます。また、これらの広告の配信には、Cookieやウェブビーコンなどの技術が使用されることがあります。当サイトは、第三者の広告コンテンツの配信に伴う、ユーザーの情報の収集や利用について、責任を負いません。</Text>
               <Text m="1rem">第３条【YouTube利用規約への同意】</Text>
               <Text m="0.5rem">当サイトは、YouTubeのAPIを利用して運営されています。当サイトを利用することにより、ユーザーはYouTube利用規約に同意したものとみなされます。</Text>
               <Text m="0.5rem">YouTube利用規約は、以下のURLから確認することができます。</Text>
               <Text m="0.5rem"><Link href="https://www.youtube.com/static?template=terms">https://www.youtube.com/static?template=terms</Link></Text>
               <Text m="1rem">第４条【個人情報の取り扱い】</Text>
               <Text m="0.5rem">本サービスとの利用に際して利用者から取得した氏名、メールアドレス、住所、電話番号等の個人情報は、別途定める「プライバシーポリシー」に則り取り扱われます。</Text>
               <Text m="1rem">第５条【著作権等知的財産権】</Text>
               <Text m="0.5rem">本サービスのプログラム、商品写真その他の知的財産権は運営者に帰属します。利用者は、当該情報を私用目的で利用される場合にかぎり使用できます。運営者に無断で、それを越えて、使用（複製、送信、譲渡、二次利用等を含む）することは禁じます。</Text>
               <Text m="1rem">第６条【クッキー(Cookie)】</Text>
               <Text m="0.5rem">１． 運営者は、次の目的で、「クッキー(cookie)」と呼ばれる技術を使用する場合があります。</Text>
               <Text m="0.5rem">・利用者が本サイトに再訪問された際、より便利に本サイトをご利用いただく為。</Text>
               <Text m="0.5rem">・本サイトの利用状況を計測・分析をする為</Text>
               <Text m="0.5rem">２． 利用者がクッキーを発行するコンテンツをご訪問された場合、利用者のコンピュータにクッキーが記録されますが、記録される情報には、利用者を個別に識別できる情報は一切含まれていません</Text>
               <Text m="0.5rem">３． 利用者ご自身でブラウザの設定を変更することにより、クッキーの受け取りを拒否することもできます。ただし、この場合には、本サービスの一部がご利用できなくなることがあります。</Text>
               <Text m="1rem">第７条【禁止事項】</Text>
               <Text m="0.5rem">運営者は、利用者が以下の行為を行うことを禁じます。</Text>
               <Text m="0.5rem">１． 運営者または第三者に損害を与える行為、または損害を与える恐れのある行為</Text>
               <Text m="0.5rem">２． 運営者または第三者の財産、名誉、プライバシー等を侵害する行為、または侵害する恐れのある行為</Text>
               <Text m="0.5rem">３． 公序良俗に反する行為、またはその恐れのある行為</Text>
               <Text m="0.5rem">４． 他人のメールアドレスを登録するなど、虚偽の申告、届出を行う行為</Text>
               <Text m="0.5rem">５． コンピュータウィルス等有害なプログラムを使用または提供する行為</Text>
               <Text m="0.5rem">６． 法令に違反する行為、またはその恐れがある行為</Text>
               <Text m="0.5rem">７． 特定個人に対する誹謗中傷（名誉毀損，信用毀損，侮辱等）および脅迫をするような書き込み行為</Text>
               <Text m="0.5rem">８． 本サービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為</Text>
               <Text m="0.5rem">９． その他運営者が不適切と判断する行為</Text>
               <Text m="0.5rem">上記に違反した場合、運営者は利用者に対し損害賠償請求をすることができることに利用者は同意します。</Text>
               <Text m="0.5rem">また、当サイトの独断で書き込みの削除を行う事を利用者は同意します。</Text>
               <Text m="1rem">第８条【本サービスの提供の停止等】</Text>
               <Text m="0.5rem">運営者は、以下各号の事由に起因する場合、事前告知なくして本サービスの全部又は一部を停止又は中止、内容変更することができ、当該事由に起因して利用者又は第三者に発生した損害や損失について、一切の責任を負うことなく、費用及び損害の賠償又は補償を免れるものとします。</Text>
               <Text m="0.5rem">１． 本サービス提供のため、定期的又は緊急にコンピュータシステムの保守・点検を行う場合</Text>
               <Text m="0.5rem">２． 本サービス提供のためのコンピュータシステムの障害及び第三者からの不正アクセス、コンピュータウイルスの感染などにより本サービス提供が不可能となった場合</Text>
               <Text m="0.5rem">３． 法律、条令、規則などに基づく措置により本サービス提供が不可能となった場合</Text>
               <Text m="0.5rem">４． 地震、停電、火災、天災地変などの非常事態により本サービス提供が不可能となった場合</Text>
               <Text m="0.5rem">５． 戦争、革命、内乱、騒擾、暴動、労働争議などにより、本サービス提供が不可能となった場合</Text>
               <Text m="0.5rem">６． その他、運営者がやむを得ないと判断した場合</Text>
               <Text m="1rem">第９条【登録抹消】</Text>
               <Text m="0.5rem">運営者は、利用者が以下のいずれかに該当する場合には、事前の通知なく、チャンネル登録を抹消することができるものとします。</Text>
               <Text m="0.5rem">１． 以下に該当する内容や表現を含むチャンネル</Text>
               <Text m="0.5rem">・公序良俗に反する内容・表現(反社会的な内容・表現)</Text>
               <Text m="0.5rem">・他人の名誉を傷つけたり、著しい誹謗中傷のある内容・表現</Text>
               <Text m="0.5rem">・他人の個人情報を承諾なしに公開している内容・表現</Text>
               <Text m="0.5rem">・利用者以外の自然人・法人・団体・組織などの第三者に成りすました内容・表現</Text>
               <Text m="0.5rem">・著作権、肖像権、工業所有権その他の知的所有権を侵害する恐れのある内容・表現</Text>
               <Text m="0.5rem">・猥褻、アダルト関連の法律・条令・規則などに抵触、又はその恐れのある内容・表現</Text>
               <Text m="0.5rem">・売春、買春、援助交際を助長する内容・表現</Text>
               <Text m="0.5rem">・テロ、薬物、犯罪行為に関する情報</Text>
               <Text m="0.5rem">２． 本サービスの運営を妨害するおそれのある内容・表現、行為</Text>
               <Text m="0.5rem">３． 本サービスの信用を失墜、毀損させる情報及び内容・表現、行為</Text>
               <Text m="0.5rem">４． その他、運営者が不適当と認めた情報及び内容・表現、行為</Text>
               <Text m="1rem">第１０条【免責事項】</Text>
               <Text m="0.5rem">１． 運営者は、本サービス内容により発生あるいは誘発された損害や損失、情報の利用により得た成果、又はその情報自体の合法性や安全性、道徳性、正確性、権利の許諾について一切の責任を負わないものとします。</Text>
               <Text m="0.5rem">２． 運営者は、本サービス提供において、利用者間で発生したトラブル（違法又は公序良俗に反する行為の提案、名誉毀損、プライバシー侵害、脅迫、誹謗中傷、嫌がらせなど）に関して、一切の責任を負わないものとします。</Text>
               <Text m="0.5rem">３． 運営者は、本サービスに関し、停止又は中止、内容変更、遅滞及び本サービスを通じて提供される情報・内容・表現などの消失、その他本サービスに関連して発生した損害や損失について、一切の責任を負わないものとします。</Text>
               <Text m="0.5rem">４． 運営者は、予告なしに、本サービスの運営を停止または中止し、また本サービスに掲載されている情報の全部または一部を変更する場合があります。</Text>
               <Text m="1rem">第１１条【契約解除】</Text>
               <Text m="0.5rem">１．運営者は、利用者が本規約に反する行為をした場合、即時にサービスを停止することができます。</Text>
               <Text m="0.5rem">２．前項の事由が発生したとき、運営者は利用者に損害賠償をすることができます。</Text>
               <Text m="1rem">第１２条【損害賠償】</Text>
               <Text m="0.5rem">本規約に違反した場合、運営者に発生した損害を賠償していただきます。</Text>
               <Text m="1rem">第１３条【準拠法・裁判管轄】</Text>
               <Text m="0.5rem">１．本規約は、日本法にしたがって解釈されるものとします。本サービスに関連して利用者、運営者、第三者との間で疑義、問題などが生じた場合、都度誠意をもって協議し、解決を図るものとします。</Text>
               <Text m="0.5rem">２．本規約のいずれかの規定が法律に違反していると判断される場合、かかる規定は、効力のあるその他の残りの条項をもって運営者の意向をできる限り反映するように解釈することとします。</Text>
               <Text m="0.5rem">３、本サービスに関して紛争が生じた場合には、日本法を適用し、名古屋地方裁判所を第一審の専属管轄裁判所とします。</Text>
            </Box>

            <Center><Heading>-プライバシーポリシー-</Heading></Center>
            <Box p="1rem">
               <Text m="1rem">１．個人情報保護宣言</Text>
               <Text m="0.5rem">運営者は、会員皆様へ安心・安全・信頼のサービスを提供するに当り、会員皆様の全ての個人に関する情報(以下「個人情報」といいます。)の取り扱いについて規定を定め、個人情報の適切な保護に努めてまいります。</Text>
               <Text m="1rem">２．個人情報保護方針</Text>
               <Text m="0.5rem">（１）運営者は、個人情報保護法及び通信の秘密に係る電気通信事業法の規定その他の関係法令を遵守します。</Text>
               <Text m="0.5rem">（２）運営者は、電気通信事業における個人情報保護に関するガイドライン（平成16年8月31日総務省告示第695号）を遵守します。</Text>
               <Text m="0.5rem">（３）運営者では取り扱うすべての個人情報に対し、適切な管理を行います。</Text>
               <Text m="0.5rem">（４）運営者で業務に従事するすべての者は、会員皆様をはじめとする各種個人情報を守り、その信頼に応えます。</Text>
               <Text m="0.5rem">（５）運営者は、情報への不正アクセス、情報の紛失、破壊、改ざん及び漏えいなどを防ぐため、必要かつ適切な安全管理措置を講じるとともにその改善に努めます。</Text>
               <Text m="1rem">３．個人情報の取扱について</Text>
               <Text m="0.5rem">（１）利用目的の特定</Text>
               <Text m="0.5rem">運営者では、取得した会員の氏名、住所、電話番号等の個人情報を下記の目的で利用します。</Text>
               <Text m="0.5rem">本サービスの提供、会員の本人確認、お問合せ対応、運営者のサービス等の変更・休廃止の通知、運営者が提供する他サービスのお知らせ・各種イベント・キャンペーンの案内に利用いたします。</Text>
               <Text m="0.5rem">（２）個人情報の開示</Text>
               <Text m="0.5rem">運営者は、会員から自己に関する個人情報の開示の請求があった場合は、本人確認を行ったうえで、これに応じます。また、個人情報の内容の訂正等の申出があった場合も、速やかに対応いたします。</Text>
               <Text m="0.5rem">（３）個人情報の利用・提供</Text>
               <Text m="0.5rem">本サービスを提供するために必要な範囲でお客さまの個人情報を取得し、取得目的の範囲内で利用・提供を行います。</Text>
               <Text m="0.5rem">（４）第三者提供</Text>
               <Text m="0.5rem">運営者は、個人情報を取得目的の範囲内で利用するとともに、適切な方法で管理し、個人情報の提供先とその利用目的を通知し承諾を得ることを行わない限り、第三者に個人情報を開示、提供しないものとします。ただし、次の場合はこの限りではありません。バズクラッチが行う個人情報の第三者提供は以下の通りです。</Text>
               <Text m="0.5rem">・刑事訴訟法第２１８条（令状による差押え・捜索・検証）その他同法の定めに基づく強制の処分が行なわれた場合。</Text>
               <Text m="0.5rem">・特定電気通信役務提供者の損害賠償責任の制限及び発信者情報の開示に関する法律第４条（発信者情報の開示請求等）に基づく開示請求の要件が充足された場合。</Text>
               <Text m="0.5rem">・生命、身体又は財産の保護のために必要があると運営者が判断した場合。</Text>
               <Text m="1rem">４．個人情報のお問い合わせ</Text>
               <Text m="0.5rem">運営者保有の個人情報の取り扱いに関するお問い合わせは、以下までお願いします。</Text>
               <Text m="0.5rem">mail@buzz-clutch.com までお問合せください</Text>
               <Text m="1rem">５. 個人情報の収集、利用および提供</Text>
               <Text m="0.5rem">当サイトでは、ユーザーの個人情報を、以下の目的で収集し、利用します。</Text>
               <Text m="0.5rem">ユーザー登録の際には、氏名、メールアドレス、パスワードなどの情報を収集し、ユーザーアカウントの作成やログインに利用します。</Text>
               <Text m="0.5rem">お問い合わせの際には、氏名、メールアドレス、お問い合わせ内容などの情報を収集し、お問い合わせへの対応に利用します。</Text>
               <Text m="0.5rem">ユーザーの利用状況の分析やマーケティング活動のために、アクセスログやCookieなどの情報を収集し、利用します。</Text>
               <Text m="0.5rem">ユーザーデータは、当ウェブサイトのサーバーに保存されます。データベースは、暗号化技術を使用して保護され、不正アクセスや漏洩を防止するための対策を講じています。</Text>
               <Text m="0.5rem">なお、ユーザーデータは法令に基づく場合や、当ウェブサイトの運営に必要な場合を除き、第三者に提供することはございません。</Text>
               
               <Text m="1rem">６. You Tube APIサービスの利用</Text>
               <Text m="0.5rem">当サイトではチャンネル所有者の許可を得て、You Tube APIサービスを利用し、チャンネル詳細ならびにビデオ詳細を掲載をしています。</Text>
               <Text mt="2rem">以上</Text>
               <Text m="0.5rem">令和２年07月21日　制定</Text>
               <Text m="0.5rem">令和５年09月08日　改定</Text>
            </Box>
         </Box>
      </Layout>
   )
}
export default Disclaimers;